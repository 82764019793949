import React from 'react';

import './styles/screenshotCol.css';

function ScreenshotCol({
  firstColour, firstText, secondColour, secondText, isSecondCol
}) {
  const firstBgColour = {
    backgroundColor: firstColour
  };

  const secondBgColour = {
    backgroundColor: secondColour
  };

  return (
    <div className={!isSecondCol ? 'container my-5 mx-2' : 'container mx-2 second-col'}>
      <div className="bg-color p-5  d-flex justify-content-center" style={firstBgColour}>
        <img src={require('../../assets/home.png')} className="screenshot-image" alt="Logo" />
      </div>
      <div className="text-container">
        <p className="screenshot-text">
          {firstText}
        </p>
      </div>

      <div className="bg-color p-5 d-flex justify-content-center" style={secondBgColour}>
        <img src={require('../../assets/home.png')} className="screenshot-image" alt="Logo" />
      </div>
      <div className="text-container">
        <p className="screenshot-text">
          {secondText}
        </p>
      </div>
    </div>

  );
}

export default ScreenshotCol;
