import React from 'react';
import SideNavBar from './SideNavBar';

import './styles/topNavBar.css';

function TopNavBar() {
  return (
    <div className="d-flex top-nav-container">
      <div className="d-flex justify-content-start col-6 h-100 p-2">
        <a href="/">
          <img src={require('../../assets/homeLogo.png')} className="logo" alt="Baz&amp;Go Logo" />
        </a>
      </div>
      <div className="d-flex align-items-center justify-content-end col-6">
        <SideNavBar />
      </div>
    </div>
  );
}

export default TopNavBar;
