import React from 'react';
import TopNavBar from '../../components/navigation/TopNavBar';
import ScreenshotCol from '../../components/home/ScreenshotCol';

import './styles/homeScreen.css';

function HomeScreen() {
  return (
    <div>
      <div className="h-100">
        <TopNavBar />
      </div>
      <div className="h-100">
        <div className="container-fluid p-0 h-100 w-100">
          <div id="mainContent" className="d-flex flex-column">
            {/* Adding row to div will generate horizontal scroll */}
            <div className="h-100 align-items-center">
              <p className="col-12 text-center title">Realiza tus compras en bazares de manera más sencilla gracias a Baz&amp;Go </p>
            </div>
            <div className="h-100 align-items-center">
              <img src={require('../../assets/amarillo1.png')} className="main-image" alt="Logo" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <ScreenshotCol firstColour="#FFEEDF" firstText="Los productos organizados por categorías para que resulte más sencillo encontrar lo que buscas" secondColour="#EDFFDF" secondText="Opciones de color y tamaño que podrás elegir según tu preferencia" />
            </div>
            <div className="col-6">
              <ScreenshotCol isSecondCol firstColour="#FFDFDF" firstText="Una gran variedad de productos a tu disposición" secondColour="#DFF9FF" secondText="Aplicación sencilla y muy intuitiva de utilizar" />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default HomeScreen;
