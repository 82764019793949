import React, { useState } from 'react';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import {
  Nav,
  Modal,
  ModalBody
} from 'reactstrap';

import './styles/sideNavBar.css';

function SideNavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => { setIsOpen(!isOpen); };

  return (
    <div className="d-flex align-items-center flex-column justify-content-center">
      {!isOpen
        && (
          <div className="element align-self-end overlay">
            <p className="px-1">Menú</p>
            <IoMdMenu size={25} onClick={toggle} />
          </div>
        )}
      <div id="sideNav" className="d-flex flex-row justify-content-center align-items-center sidenav">
        <Modal className="sidenav d-flex" isOpen={isOpen} toggle={toggle} navbar>
          <ModalBody className="d-flex align-content-center justify-content-center">
            <div className="d-flex align-self-start position-fixed right">
              <IoMdClose size={25} onClick={toggle} />
            </div>
            <Nav vertical className="align-top justify-content-center p-5">
              <li className="d-flex justify-content-center list-item">
                <a href="/contacto" className="text-decoration-none text-dark">
                  CONTACTO
                </a>
              </li>
              <li className="d-flex justify-content-center list-item">
                <a href="/preguntas-frecuentes" className="text-decoration-none text-dark">
                  PREGUNTAS FRECUENTES
                </a>
              </li>
            </Nav>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}

export default SideNavBar;
