import React from 'react';
import {
  BrowserRouter as Router,
}
  from 'react-router-dom';
import HomeScreen from './pages/home/HomeScreen';

export default function App() {
  return (
    <Router>
      <div>
        <HomeScreen />
      </div>
    </Router>
  );
}
